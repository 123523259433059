<template>
  <!-- Loader -->
  <div class="row q-col-gutter-md" v-if="loading">
    <div class="col-xs-12 col-sm-6 col-md-5 col-lg-7 productImage">
      <div class="productImageLarge">
        <q-skeleton
          width="100%"
          :height="isMobile ? '220px' : '390px'"
          class="q-mx-auto"
        />
      </div>
      <div class="productThumb">
        <q-skeleton width="40px" height="40px" class="q-mb-md" />
        <q-skeleton width="40px" height="40px" class="q-mb-md" />
        <q-skeleton width="40px" height="40px" class="q-mb-md" />
        <q-skeleton width="40px" height="40px" class="q-mb-md" />
      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-7 col-lg-5 q-mt-md">
      <q-skeleton type="text" width="80px" class="q-mb-sm" />
      <q-skeleton type="rect" width="100%" class="q-mb-sm" />
      <!-- <q-skeleton type="rect" width="30%" class="q-mb-md" /> -->
      <!--<q-skeleton type="text" width="140px" height="30px" class="q-mb-sm" />-->
      <q-skeleton type="text" width="180px" height="30px" class="q-mb-sm" />
      <q-skeleton
        type="rect"
        v-if="!isMobile"
        width="120px"
        height="30px"
        class="q-mb-md"
      />
      <!--<div class="row q-col-gutter-md q-mb-sm">
        <div class="col-1"
          ><q-skeleton type="circle" width="24px" height="24px" class="q-mb-sm"
        /></div>
        <div class="col-1"
          ><q-skeleton type="circle" width="24px" height="24px" class="q-mb-sm"
        /></div>
        <div class="col-1"
          ><q-skeleton type="circle" width="24px" height="24px" class="q-mb-sm"
        /></div>
      </div>-->
      <!-- <div class="row q-col-gutter-md q-mb-md">
        <div class="col-2"
          ><q-skeleton type="rect" width="100%" height="30px" class="q-mb-sm"
        /></div>
        <div class="col-2"
          ><q-skeleton type="rect" width="100%" height="30px" class="q-mb-sm"
        /></div>
        <div class="col-2"
          ><q-skeleton type="rect" width="100%" height="30px" class="q-mb-sm"
        /></div>
      </div> -->
      <q-skeleton
        type="QBtn"
        v-if="!isMobile"
        width="200px"
        height="40px"
        class="q-mb-md"
      />
    </div>
  </div>
  <!-- Loader -->
  <div
    class="row product-details"
    v-else-if="productDetail && productDetail.productID"
  >
    <div class="col-xs-12 col-sm-6 col-md-5 col-lg-7 productImage">
      <div class="product-wishlist" v-if="isMobile">
        <AddToFavorite :products="selectedProduct" />
      </div>
      <div class="productImageLarge">
        <!-- swiper1 -->

        <span
          class="productAgeRestricted"
          v-if="selectedProduct && selectedProduct.hasAgeRestriction"
        >
          Age Restricted
        </span>
        <swiper
          class="swiper gallery-top"
          :options="swiperOptionTop"
          ref="swiperTop"
          @hook:mounted="swiperTopMounted"
          @slideChange="
            () => {
              zoomEffectApplied = false
              slideChange()
            }
          "
        >
          <swiper-slide v-for="(image, index) of images" :key="index">
            <img
              :src="image.Large"
              :data-zoom="image.Large"
              class="swiper-lazy"
            />
          </swiper-slide>
          <div
            class="swiper-button-next swiper-button-white"
            slot="button-next"
            v-if="!isMobile"
          >
          </div>
          <div
            class="swiper-button-prev swiper-button-white"
            slot="button-prev"
            v-if="!isMobile"
          >
          </div>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <!-- swiper2 Thumbs -->
      </div>
      <div
        class="productThumb"
        @mouseover="($event) => changeProductSlide($event, this)"
      >
        <swiper class="swiper" :options="swiperOptionThumbs" ref="swiperThumbs">
          <swiper-slide
            style="width: 40px; height: 40px; max-height: 40px"
            v-for="(image, index) of images"
            :key="index"
            :data-swiper-slide-index="index"
          >
            <!-- <img :src="image.Large" /> -->
            <!-- <img :src="image.small" class="swiper-lazy" /> -->
            <q-img :src="image.Small" height="40px" class="swiper-lazy" />
          </swiper-slide>
          <!-- <div
            class="swiper-button-next swiper-button-white"
            slot="button-next"
          >
          </div>
          <div
            class="swiper-button-prev swiper-button-white"
            slot="button-prev"
          >
          </div> -->
        </swiper>
      </div>
      <div class="img-zoom-container"></div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-7 col-lg-5 productDetail">
      <h1 class="product-title text-h6">
        <router-link
          class="no-underline product-title-container"
          style="color: inherit"
          :to="{ name: 'Detail', params: { seoName: selectedProduct.seoName } }"
          v-if="isDialog && selectedProduct.seoName"
        >
          <span class="block">
            {{ selectedProduct.manufacturerName }}
          </span>
          {{ selectedProduct.title }}
        </router-link>
        <template v-else>
          <span class="block">
            {{ selectedProduct.manufacturerName }}
          </span>
          {{ selectedProduct.title }}
        </template>
      </h1>
      <div class="product-upc" v-if="selectedProduct.barCode">
        SKU : {{ selectedProduct.barCode }}</div
      >
      <div class="product-wishlist" v-if="!isMobile">
        <!-- <AddToWishlist :products="selectedProduct" /> -->
        <AddToFavorite :products="selectedProduct" />
      </div>
      <div v-if="businessConfigurations.enableReviews" class="product-review">
        <q-rating
          :value="reviewRating"
          max="5"
          size="1em"
          color="orange"
          icon="star_border"
          icon-selected="star"
          icon-half="star_half"
          no-dimming
          readonly
        />
        <q-item-label
          v-html="
            `(${
              selectedProduct.reviewStats
                ? selectedProduct.reviewStats.total
                : 0
            } ratings)`
          "
        ></q-item-label>
      </div>

      <div class="product-price">
        <span class="price">
          {{ selectedProduct.price | currency }}
          <span class="q-ml-sm text-strike" v-if="false">
            {{ selectedProduct.compareAtPrice | currency }}
          </span>
        </span>
        <!--<q-chip class="price-off"> 30% Off </q-chip>-->
      </div>
      <div
        class="price-vary-label text-negative"
        v-if="
          businessConfigurations.showProductPriceLocationDisclaimer &&
          selectedFulfillmentCodeName !== 'sd'
        "
      >
        Product pricing and availability may vary by location.
      </div>
      <div class="product-option" v-if="Object.keys(variantsGroup).length">
        <div
          v-for="key in Object.keys(variantsGroup)"
          :key="key"
          :class="variantClass[key] || variantClass['DEFAULT']"
        >
          <div class="product-option--label"
            >{{ key }}: {{ selectedVariant[key] }}</div
          >
          <template v-if="key == 'COLOR'">
            <a
              v-for="color in variantsGroup[key]"
              :key="color"
              href="javascript:void(0);"
              :style="{ background: color.split('|')[1] || '#000' }"
              :class="{
                selected:
                  selectedVariant.COLOR && selectedVariant.COLOR == color,
                variant_combination_not_present: !isVariantCombinationAvailable(
                  key,
                  color
                ),
                'color--active': isMobile,
                'color--hover': !isMobile,
              }"
              @click="variantSelected($event, key, color)"
            >
            </a>
          </template>
          <template v-else>
            <a
              v-for="value in variantsGroup[key]"
              :key="value"
              href="javascript:void(0);"
              :class="{
                selected: selectedVariant[key] && selectedVariant[key] == value,
                variant_combination_not_present: !isVariantCombinationAvailable(
                  key,
                  value
                ),
                'size--active': isMobile,
                'size--hover': !isMobile,
              }"
              @click="variantSelected($event, key, value)"
            >
              {{ value }}
            </a>
            <a
              v-if="key == 'SIZE'"
              href="javascript:void(0);"
              class="size-guide"
            >
              Size guide
            </a>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <OrderCartButton
            class="inline-block vertical-middle q-mr-md"
            :productId="selectedProduct.productID"
            :product="selectedProduct"
            :updateState="true"
            v-if="addToCartButton === 'order'"
          />
          <AddToCartBtn
            :showFlyAnimation="isMobile && !isDialog"
            class="inline-block vertical-middle q-mr-md"
            v-else
            :productFulFillments="
              selectedProduct.fulFillments
                ? selectedProduct.fulFillments
                : selectedProduct.productFulfillments
            "
            :productId="selectedProduct.productID"
            :isOnDetailPage="true"
            :isinstock="selectedProduct.isinstock"
          />
          <AddToWishlist
            :products="selectedProduct"
            class="product-detail-wishlist-mobile"
            label="Add to wishlist"
          />
        </div>
        <!-- Start: Social share buttons -->
        <!--<div
          class="social-buttons"
          v-if="businessConfigurations.enableSocialShareButton"
        >
          <span class="q-mr-sm">Share</span>
          <q-btn
            icon="fab fa-facebook-f"
            no-caps
            unelevated
            outline
            round
            color="primary"
            push
            size="10px"
            @click="socialShare('facebook')"
            class="q-mr-xs"
          />
          <q-btn
            icon="fab fa-twitter"
            no-caps
            unelevated
            outline
            round
            color="primary"
            push
            size="10px"
            @click="socialShare('twitter')"
            class="q-mr-xs"
          />
          <q-btn
            icon="fab fa-linkedin-in"
            no-caps
            unelevated
            outline
            round
            color="primary"
            push
            size="10px"
            @click="socialShare('linkedin')"
          />
        </div>-->
        <!-- End: Social share buttons -->
        <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 q-mt-sm"
          v-if="
            selectedProduct.linkedProduct &&
            selectedProduct.linkedProduct.productId
          "
        >
          <q-item class="item-start link-products">
            <q-item-section class="linked-product bg-secondary">
              <q-item-label>
                <q-badge color="secondaryOnBody" class="inline-block">
                  Sales Link
                </q-badge>
                <q-item class="item-start">
                  <q-item-section avatar>
                    <box-image
                      :link="
                        selectedProduct.linkedProduct.isSellable
                          ? {
                              name: 'Detail',
                              params: {
                                seoName: selectedProduct.linkedProduct.seoName,
                              },
                            }
                          : ''
                      "
                      :imageSrc="selectedProduct.linkedProduct.profileImage"
                      width="40px"
                    />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label class="product-title ellipsis d">
                      <router-link
                        v-if="selectedProduct.linkedProduct.isSellable"
                        :to="{
                          name: 'Detail',
                          params: {
                            seoName: selectedProduct.linkedProduct.seoName,
                          },
                        }"
                        v-text="selectedProduct.linkedProduct.title"
                      />
                      <template v-else>
                        {{ selectedProduct.linkedProduct.title }}
                      </template>
                    </q-item-label>
                    <q-item-label class="row no-wrap items-center" caption>
                      <q-item-label
                        class="product-qty"
                        v-html="
                          `${
                            selectedProduct.linkedProduct.linkedQty
                          } X ${$options.filters.currency(
                            selectedProduct.linkedProduct.price
                          )}`
                        "
                      />
                    </q-item-label>
                  </q-item-section>
                  <q-item-section class="cartProductSide" side>
                    <q-item-label
                      class="product-price"
                      v-html="
                        $options.filters.currency(
                          selectedProduct.linkedProduct.finalPrice
                        )
                      "
                    />
                  </q-item-section>
                </q-item>
              </q-item-label>
            </q-item-section>
          </q-item>
        </div>
      </div>

      <NearbyLocationList
        v-if="selectedFulfillmentCodeName != 'sd'"
        :loading="showLocationSkeleton"
        :locations="nearByLocationList"
        :productID="productDetail.productID"
        v-show="!productDetail.isinstock"
      />
      <ProductAvailability
        :productID="productDetail.productID"
        v-if="!selectedProduct.isinstock && selectedFulfillmentCodeName == 'sd'"
      />
    </div>
  </div>
</template>
<script>
import { debounce } from 'quasar'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Drift from 'drift-zoom'

import OrderCartButton from 'components/OrderCartButton'
import AddToCartBtn from '../AddToCartBtn'
import AddToFavorite from '../AddToFavorite'
import AddToWishlist from '../AddToWishlist'
import NearbyLocationList from 'components/detail/NearbyLocationList'
import ProductAvailability from './ProductAvailability.vue'

import {
  changeProductSlide,
  convertArrayToObject,
  addInSortFashion,
} from 'src/utils'

import {
  detailSwiperOption,
  swiperOptionTop,
  swiperOptionThumbs,
} from 'src/constants'

import 'swiper/css/swiper.css'

// var newwindow
// const createPop = (url, name) => {
//   newwindow = window.open(
//     url,
//     name,
//     'width=560,height=540,toolbar=0,menubar=0,location=0'
//   )
//   if (window.focus) {
//     newwindow.focus()
//   }
// }

export default {
  name: 'ProductDetail',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    productDetail: {
      type: Object,
      default: () => {
        return new Object({})
      },
    },
    isRoutingEnabled: {
      type: Boolean,
      default: false,
    },
    isInputIsProduct: {
      type: Boolean,
      default: false,
    },
    addToCartButton: {
      type: String,
      default: 'cart',
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Swiper,
    OrderCartButton,
    SwiperSlide,
    AddToCartBtn,
    AddToFavorite,
    NearbyLocationList,
    AddToWishlist,
    ProductAvailability,
  },
  data() {
    return {
      productTitle: this.productDetail.title || '',
      productDescription: this.productDetail.shortDescription || '',
      productImage:
        this.productDetail.images && this.productDetail.images.length
          ? this.productDetail.images[0].similarImages[0].imageURL
          : '',
      cartDelivery: '',
      swiperOption: detailSwiperOption,
      swiperOptionTop: swiperOptionTop,
      swiperOptionThumbs: swiperOptionThumbs,
      selectedVariantId: '',
      selectedVariant: {
        COLOR: 'Pink',
      },
      variantClass: {
        COLOR: 'product-option--color',
        DEFAULT: 'product-option--size',
      },
      zoomEffectApplied: false,
      showLocationSkeleton: false,
      nearByLocationList: {},
      locationsLoaded: false,
    }
  },
  mounted() {
    if (this.productDetail?.isinstock === false && !this.locationsLoaded) {
      this.fetchNearByLocationList()
    }
  },
  computed: {
    reviewRating() {
      return this.selectedProduct.reviewStats
        ? this.selectedProduct.reviewStats.overAllRating
        : 0
    },
    selectedProduct() {
      if (this.isInputIsProduct) {
        return this.productDetail
      }
      if (this.productDetail.variants && this.productDetail.variants.length) {
        for (let product of this.productDetail.variants) {
          if (
            (this.selectedVariantId == '' && product.isDefaultSelected) ||
            this.selectedVariantId == product.productVariantValueID
          ) {
            product.productID = product.productVariantValueID
            return product
          }
        }
      }

      return this.productDetail
    },
    variants() {
      let variants = {}
      let productDetail = JSON.parse(JSON.stringify(this.productDetail))
      if (productDetail.variants.length) {
        for (let variant of productDetail.variants) {
          let variantOptions = {}
          let key = ''

          for (let variantValue of variant.productVariantValues) {
            variantOptions[variantValue.codeName] = variantValue.value
            key += variantValue.value
          }

          variant.variantOptions = variantOptions
          variants[key] = variant
        }
      }

      return variants
    },
    hasVariants() {
      if (this.productDetail.variants.length) {
        return true
      }
      return false
    },
    variantsGroup() {
      let variantsGroup = {}

      for (let key in this.variants) {
        let variant = this.variants[key]

        for (let variantOptionKey in variant.variantOptions) {
          if (!variantsGroup.hasOwnProperty(variantOptionKey)) {
            variantsGroup[variantOptionKey] = []
          }

          if (
            !variantsGroup[variantOptionKey].includes(
              variant.variantOptions[variantOptionKey]
            )
          ) {
            variantsGroup[variantOptionKey] = addInSortFashion(
              variant.variantOptions[variantOptionKey],
              variantsGroup[variantOptionKey]
            )
            // variantsGroup[variantOptionKey].push(
            //   variant.variantOptions[variantOptionKey]
            // )
          }
        }
      }

      return variantsGroup
    },
    selectedProductVariantOptions() {
      let variantOptions = {}

      if (this.selectedProduct.productVariantValues) {
        for (let variant of this.selectedProduct.productVariantValues) {
          variantOptions[variant.codeName] = variant.value
        }
      }
      this.$store.commit(
        'product/UPDATE_CURRENTSELECTEDPRODUCT_VARIANT',
        variantOptions
      )

      return variantOptions
    },
    images() {
      let images = []
      if (this.selectedProduct.images && this.selectedProduct.images.length) {
        for (let image of this.selectedProduct.images) {
          let options = {}

          for (let option of image.similarImages) {
            options[option.imageType] = option.imageURL
          }

          images.push(options)
        }
      } else {
        images.push({
          Small: this.$options.filters.defaultProductImage('', 'Small'),
          Large: this.$options.filters.defaultProductImage('', 'Large'),
        })
      }

      let response = [...images]
      // while (response.length < 5) {
      //   response = [...response, ...images]
      // }
      return response
    },
  },
  methods: {
    applyZoomEffect() {
      if (this.isMobile) return
      setTimeout(() => {
        let productImage =
          document.querySelector(
            '.productImageLarge .swiper-slide-active img'
          ) ||
          document.querySelector(
            '.productImageLarge .swiper-slide-duplicate-active img'
          )
        if (productImage) {
          let zoomContainer =
            document.getElementsByClassName('img-zoom-container')[0]
          let parentHeight =
            productImage?.parentElement?.clientHeight || productImage.height
          let parentWidth =
            productImage?.parentElement?.clientWidth || productImage.width
          new Drift(productImage, {
            paneContainer: zoomContainer,
            inlinePane: false,
            onShow: function () {
              zoomContainer.style.cssText = `
                position: absolute;
                left:100%;
                z-index: 20000;
                opacity: 1;
                width: ${parentWidth < 300 ? 300 : parentWidth}px;
                height: ${parentHeight < 300 ? 300 : parentHeight}px;
                border: 1px solid var(--q-color-primary);
                box-shadow: 0px 0px 5px 0px var(--q-color-primary);
                background-color: #fff;
              `
            },
            onHide: function () {
              zoomContainer.style.cssText = ``
            },
          })
          this.zoomEffectApplied = false
        }
      }, 500)
    },
    slideChange() {
      if (this.zoomEffectApplied) return
      this.applyZoomEffect()
    },
    changeProductSlide: debounce(
      ($event, _this) => changeProductSlide($event, _this.$refs.swiperTop),
      50
    ),
    variantSelected(event, key, value) {
      let filterVariants = {}

      if (this.isVariantCombinationAvailable(key, value)) {
        filterVariants = {
          ...this.selectedVariant,
          [key]: value,
        }
      } else {
        filterVariants = {
          [key]: value,
        }
      }

      let availableVariants = this.filterVariants(filterVariants)

      if (this.isRoutingEnabled) {
        const path = `/detail/${availableVariants[0].seoName}`
        if (this.$route.path !== path) {
          this.$router
            .push({
              name: 'Detail',
              params: { seoName: availableVariants[0].seoName },
            })
            .catch(() => {})
        }
      } else {
        this.selectedVariantId = availableVariants[0].productVariantValueID
        this.selectedVariant = Object.assign(
          {},
          this.selectedProductVariantOptions
        )
        this.zoomEffectApplied = false
        this.applyZoomEffect()
      }
    },
    filterVariants(selectedVariant) {
      let variants = []

      for (let key in this.variants) {
        let variant = this.variants[key]
        let isVariantMatched = true

        for (let key in selectedVariant) {
          if (variant.variantOptions[key] !== selectedVariant[key]) {
            isVariantMatched = false
          }
        }

        if (isVariantMatched) {
          variants.push(variant)
        }
      }

      return variants
    },
    isVariantCombinationAvailable(key, value) {
      let availableVariants = this.filterVariants({
        ...this.selectedVariant,
        [key]: value,
      })
      return availableVariants.length
    },
    swiperTopMounted() {
      this.$nextTick(() => {
        if (this.$refs.swiperTop) {
          const swiperTop = this.$refs.swiperTop.$swiper
          const swiperThumbs = this.$refs.swiperThumbs.$swiper
          swiperTop.controller.control = swiperThumbs
          swiperThumbs.controller.control = swiperTop
        }
        if (!this.isMobile) {
          /* Start: Image zoom on hover */
          let imgContainer =
            document.querySelector('.productImageLarge .swiper-slide-active') ||
            document.querySelector(
              '.productImageLarge .swiper-slide-duplicate-active'
            )
          if (imgContainer) {
            this.applyZoomEffect()
            this.zoomEffectApplied = true
          }
          /* End: Image zoom on hover */

          /* Start: first thumbnail selected By default when product render for the first time */
          let targetElement = document.querySelector(
            '.productThumb .swiper-slide-active'
          )
          if (targetElement) targetElement.classList.add('slide-active')
          /* End: first thumbnail selected By default when product render for the first time */
        }
      })
    },
    fetchNearByLocationList() {
      let params = {
        locationID: this.currentLocationId,
        productID: this.selectedProduct.productID,
      }
      this.locationsLoaded = true
      this.showLocationSkeleton = true
      this.$store
        .dispatch('product/getAvailableNearByStore', params)
        .then((response) => {
          if (response && response.success) {
            this.nearByLocationList = Object.assign(
              {},
              this.nearByLocationList,
              convertArrayToObject(response.data, 'locationId')
            )
          }
        })
        .finally(() => (this.showLocationSkeleton = false))
    },
    /*socialShare(platform) {
      let location = {
        href: 'https://qa.dev.ftxcommerce.com/detail/6-pack-breew-rite-4-cup-basket-filters-200-ct',
      }
      let url
      switch (platform) {
        case 'facebook':
          url =
            'https://www.facebook.com/sharer.php?u=' +
            encodeURIComponent(location.href) +
            '&title=' +
            encodeURIComponent(this.selectedProduct.title) +
            '&description=' +
            encodeURIComponent(this.selectedProduct.shortDescription) +
            '&quote=' +
            encodeURIComponent(this.selectedProduct.title) +
            '&hashtag=' +
            encodeURIComponent('#FTxCommerce')
          break
        case 'twitter':
          url =
            'https://twitter.com/intent/tweet?text=' +
            encodeURIComponent(this.selectedProduct.title) +
            '&url=' +
            encodeURIComponent(location.href) +
            '&hashtags=' +
            encodeURIComponent('FTxCommerce')
          break
        case 'linkedin':
          url =
            'https://www.linkedin.com/sharing/share-offsite/?url=' +
            encodeURIComponent(location.href)
          break
      }
      // createPop(url, '')
      window.open(url, '_blank')
    },*/
  },
  created() {
    this.selectedVariant = Object.assign({}, this.selectedProductVariantOptions)
  },
  watch: {
    productDetail(newVal, oldVal) {
      if (newVal != oldVal && !this.locationsLoaded) {
        // this.productDescription = this.selectedProduct.shortDescription
        // this.productImage = this.images[0]['Small']
        this.selectedVariant = Object.assign(
          {},
          this.selectedProductVariantOptions
        )
        if (!newVal.isinstock) {
          // this.showLocationSkeleton = true
          this.fetchNearByLocationList()
        }
      }
    },
    selectedProduct(newVal, oldVal) {
      if (newVal != oldVal && newVal.hasOwnProperty('isinstock')) {
        if (newVal.isinstock === true) this.nearByLocationList = {}
        else this.fetchNearByLocationList()
      }
    },
  },
}
</script>
<style lang="scss">
.product-detail-wishlist-mobile {
  position: relative;
  right: unset;
  margin-bottom: 0px;
  margin-top: 0px;
  display: inline-block;
  @media (max-width: 1023px) {
    // margin-bottom: 10px;
    margin-top: 10px;
  }
  @media (max-width: 600px) {
    // margin-bottom: 10px;
    margin-top: 0px;
  }
}
.locationList.product-detail-location,
.locationList.nearby-location-list {
  .q-item,
  .q-loader-noicon {
    width: 100%;
    flex: 0 1 100%;
    .location-addtocart {
      text-align: right;
    }
    @media (max-width: 1366px) {
      width: 50%;
      flex: 0 1 50%;
      .location-addtocart {
        text-align: left;
      }
    }
    @media (max-width: 1024px) {
      width: 100%;
      flex: 0 1 100%;
    }
  }
}
.productAgeRestricted {
  position: absolute;
  right: 10px;
  background: #ff0000ab;
  display: inline-block;
  padding: 3px 10px;
  font-size: 11px;
  color: #fff;
  border-radius: 15px;
  line-height: normal;
  z-index: 2;
}
/* Main Image Slider */
.productImage {
  display: flex;
  flex-direction: column;
  position: relative;
  .img-zoom-container {
    position: absolute;
    background-color: #fff !important;
  }
  .productImageLarge {
    width: 100%;
    position: relative;
    .swiper-pagination-bullets {
      bottom: 0;
    }
    .img-zoom-container {
      // z-index: 2002;
      // position: fixed;
    }
    @media (max-width: 1023px) {
      height: 320px;
      img,
      .q-img {
        width: auto;
        // width: 280px !important;
        margin: 0 auto;
        display: block;
      }
    }
    @media (max-width: 767px) {
      height: 200px;

      img,
      .q-img {
        width: auto;
        // width: 180px !important;
        margin: 0 auto;
        display: block;
      }
    }
    img,
    iframe,
    video {
      width: 100%;
      @media (max-width: 991px) {
        width: auto;
        height: auto;
        max-height: 100%;
        // width: calc(70% - 30px);
        padding: 15px;
        padding-bottom: 50px;
        margin: 0 auto;
        display: block;
      }
    }
    @media (min-width: 1024px) {
      .swiper-button-next,
      .swiper-button-prev,
      .swiper-pagination {
        display: none;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      background: rgba(0, 0, 0, 0.3);
      &:after {
        font-size: 15px;
      }
    }
    .swiper-pagination-bullet-active {
      background: $secondary;
      background: var(--q-color-secondary) !important;
    }
    .swiper-container {
      height: 100%;
      padding-bottom: 20px;
      // border: 1px solid #eeeeee;
      .swiper-pagination-bullet {
        width: 15px;
        height: 15px;
      }
    }
    .swiper-slide-active {
      position: relative;
      img {
        position: absolute;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        margin: auto;
        margin-top: 0;
        cursor: -moz-zoom-in;
        cursor: -webkit-zoom-in;
        cursor: zoom-in;
        background-color: #fff !important;
      }
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .vue-h-zoom {
        height: 100%;
        .thumbnail-area {
          // height: 70% !important;
          // width: 100% !important;
        }
      }
      img {
        position: absolute;
        top: 0px;
        bottom: auto;
        left: auto;
        right: auto;
        margin: auto;
        margin-top: 0px;
        @media (min-width: 991px) {
          top: auto;
          max-width: 100% !important;
          height: auto !important;
          // min-height: 100%;
          width: auto;
          // max-height: 100%;
          max-height: 300px;
        }
      }
    }
  }
  .productThumb {
    width: 100%;
    @media (max-width: 1023px) {
      display: none;
    }
  }
  @media (min-width: 600px) {
    padding-right: 20px;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    padding-right: 40px;
    .productImageLarge {
      width: calc(100% - 120px);
      order: 2;
      padding-left: 20px;
    }
    .productThumb {
      order: 1;
      width: 120px;
    }
  }
}
/* Main Image Slider */
/* Thumbnail Slider */
.productThumb {
  .swiper-container {
    padding: 2px;
  }
  .swiper-wrapper {
    flex-direction: column;
    transition-duration: none !important;
    transform: none !important;
  }
  .swiper-slide {
    // opacity: 0.7;
    cursor: pointer;

    img,
    .q-img {
      border: 1px solid #a2a6ac;
      border-radius: 4px;
      overflow: hidden;
      .q-img__image {
        background-size: contain !important;
      }
    }
    &.slide-active {
      opacity: 1;
      img,
      .q-img {
        border: 1px solid $primary;
        box-shadow: 0px 0px 5px 0px $primary;
        background-color: #fff !important;
      }
    }
    &.active {
      opacity: 1;
      img {
        border: 1px solid $primary;
        box-shadow: 0px 0px 5px 0px $primary;
      }
    }
    img {
      width: calc(100% - 10px);
      padding: 10px;
      @media (min-width: 1440px) {
        width: calc(100% - 10px);
        padding: 15px;
      }
    }
    & + .swiper-slide {
      margin-top: 15px;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    background: rgba(0, 0, 0, 0.3);
    &:after {
      font-size: 15px;
    }
  }
  @media (min-width: 1024px) {
    .swiper {
      // height: 480px;
      height: 380px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 100%;
      margin-top: 0;
      &:after {
        transform: rotate(90deg);
      }
    }
    .swiper-button-prev {
      top: 0;
      left: 0;
    }
    .swiper-button-next {
      top: auto;
      bottom: 0px;
      left: 0;
    }
  }
}
/* Thumbnail Slider */
/* Product Detail */
.productDetail {
  position: relative;
  h1 {
    font-size: 20px;
    line-height: 1.4rem;
    font-family: 'Poppins', sans-serif;
    color: #314154;
    span {
      font-size: 12px;
      font-family: 'Lato', sans-serif;
      color: #000000;
    }
    @media (min-width: 600px) {
      padding-right: 50px;
    }
    @media (min-width: 1681px) {
      font-size: 26px;
      line-height: 1.8rem;
      span {
        font-size: 14px;
      }
    }
  }
  .product-review {
    margin-bottom: 15px;
    .q-item__label {
      margin-left: 10px;
      display: inline-block;
      font-size: 10px;
      font-weight: bold;
      color: #393939;
      opacity: 0.5;
    }
  }
  .product-price {
    margin-bottom: 15px;

    .price {
      font-size: 24px;
      font-weight: bold;
      color: $secondary;
      color: var(--q-color-secondaryOnBody) !important;
      .text-strike {
        font-size: 16px;
        color: #c4c4c4;
      }
    }
    .price-off {
      margin: 0;
      margin-left: 15px;
      background: #ec7a7a;
      border-radius: 16px;
      font-size: 16px;
      color: #ffffff;
    }
  }
  .product-option {
    margin-bottom: 10px;
    .product-option--label {
      flex: 0 0 auto;
      width: 100%;
      max-width: 100%;
      font-size: 13px;
      margin-bottom: 8px;
      text-transform: capitalize;
    }
    .product-option--color {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 5px;
      a {
        margin-bottom: 5px;
        margin-right: 5px;
        width: 24px;
        height: 24px;
        border: 2px solid #fff;
        border-radius: 50%;
        box-shadow: 0 0 0 1px transparent;
        box-shadow: 0 0 0 1px #cccccc;
        &.color--hover:hover,
        &.color--active:active,
        // &:hover,
        &.selected {
          border-color: $secondary;
          border-color: var(--q-color-secondaryOnBody) !important;
          color: $secondary;
          color: var(--q-color-secondaryOnBody) !important;
          box-shadow: 0px 0px 0px 1px $secondary;
          box-shadow: 0px 0px 0px 1px var(--q-color-secondaryOnBody) !important;
          // box-shadow: 0 0 0 1px #cccccc;
        }
      }
    }
    .product-option--size {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 5px;
      a {
        padding: 8px 10px;
        margin-bottom: 5px;
        margin-right: 5px;
        font-size: 11px;
        color: #999999;
        border: 1px solid #999999;
        border-radius: 3px;
        text-decoration: none;
        &.size-guide {
          border: none;
          text-decoration: underline;
        }
        // &:not(.size-guide):hover,
        &.size--hover:hover,
        &.size--active:active,
        &.selected {
          border-color: $secondary;
          border-color: var(--q-color-secondaryOnBody) !important;
          color: $secondary;
          color: var(--q-color-secondaryOnBody) !important;
          box-shadow: 0px 0px 0px 1px $secondary;
          box-shadow: 0px 0px 0px 1px var(--q-color-secondaryOnBody) !important;
        }
      }
    }
    .variant_combination_not_present {
      opacity: 0.5;
      // &:hover {
      //   opacity: 1;
      // }
      &.size--hover:hover,
      &.size--active:active,
      &.color--hover:hover,
      &.color--active:active {
        opacity: 1;
      }
    }
  }
  .product-cart {
    max-width: 210px;
    &.no-rounder {
      &:hover {
        .added-cart {
          .icon {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 0px;
          }
          .dropdown {
            top: calc(100% - 1px);
            bottom: auto;
          }
        }
      }
    }
    @media (max-width: 767px) {
      margin-right: 0 !important;
    }
  }
  @media (max-width: 767px) {
    & > .product-price {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 0px;
      margin: 0;
      text-align: center;
      position: fixed;
      width: 50%;
      bottom: 0;
      left: 0;
      z-index: 99;
      height: 60px;
      line-height: 55px;
      .price {
        font-size: 20px;
      }
      .price-off {
        position: absolute;
        padding: 0;
        height: 24px;
        font-size: 11px;
        right: 10px;
        top: 0px;
        background: none;
        color: #ec7a7a;
        display: none;
      }
    }
    .product-cart {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 10px;
      position: fixed;
      width: 50%;
      max-width: 100%;
      bottom: 0;
      right: 0;
      z-index: 999;
      // z-index: 20001;
      height: 60px;
    }
  }
  .product-upc {
    margin-bottom: 15px;
    font-weight: bold;
  }
  .price-vary-label {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .social-buttons {
    margin-top: 15px;
    @media (max-width: 600px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
/* Product Detail */
/* Detail Wishlist */
.product-wishlist {
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 999;
  .q-btn {
    // width: 50px;
    // height: 50px;
    @media (min-width: 767px) {
      .q-btn__wrapper {
        padding: 0;
        min-height: auto;
      }
    }
    .icon {
      left: 0;
    }
  }
  a {
    font-size: 14px;
    color: #000000 !important;
    .icbg {
      fill: #fff;
    }
    .icborder {
      fill: #000000;
    }
    &.selected {
      color: #ec7a7a !important;
      .icbg {
        fill: currentColor;
      }
      .icborder {
        fill: currentColor;
      }
    }
  }
  @media (max-width: 767px) {
    top: 15px;
    button {
      .q-icon {
        font-size: 42px;
      }
      svg {
        width: 36px !important;
      }
    }
  }
  .wishlist-btn {
    position: relative;
    right: auto;
    margin-right: 5px;
    margin-top: 2px;
  }
}
/* Detail Wishlist */
</style>
